import { Link } from 'gatsby'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination'
import PaginationItem from '@material-ui/lab/PaginationItem'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}))

const Pager = ({ pageContext }) => {
  const { numPages, currentPage } = pageContext
  const classes = useStyles()
  return (
    <div>
      <div className={classes.root}>
        <Pagination
          count={numPages}
          page={currentPage}
          color='secondary'
          renderItem={(item) => (
            <PaginationItem
              component={Link}
              to={`/properties${item.page === 1 ? '/' : `/${item.page}`}`}
              {...item}
            />
          )}
        />
      </div>
    </div>
  )
}

export default Pager
